@media screen and (max-width: 480px) {
	.clients-filter-content {
		width: 100%;
		padding: 20px;
		height: calc(100dvh - 140px);
		position: relative;

		.clients-filter-header-actions {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 50%;

			span {
				color: #025cb7;
			}
		}

		.block-filter .block-filter-fields label {
			width: auto;
		}

		.clients-filter-actions {
			width: 100%;
			display: flex;
			justify-content: center;
			position: absolute;
			bottom: 0;
			margin: 0 -20px;
		}
	}
}