.device-mobile .clients-actions {
	display: flex;
	align-items: center;
	flex-direction: row;
	margin: 0px 20px 20px;
	justify-content: space-between;

	.clients-search-and-filter {
		display: flex;

		img {
			width: 50px;
			cursor: pointer;
			margin-left: 0px;
		}

		.ppsa-search-component {
			width: calc(100% - 50px)!important;
		}
	}

	.clients-bt-new-order {
		width: 126px;
	}
}

.device-mobile .clients-legends {
	display: flex;
	column-gap: 20px;
	margin: 10px 20px;
	flex-direction: row;
	justify-content: center;

	span {
		font-size: 12px;
		font-weight: 400;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 0px;
		text-overflow: ellipsis;

		img {
			height: 15px;
			width: 15px;
		}
	}

	span:last-child {
		padding-right: 0px;
	}
}
