@import '../../../../../../../assets/styles/theme/colors/variables.scss';

.my-filter-container {
	width: 800px;
	margin: 0 -20px;
	height: calc(100% - 30px);;

	.my-filter-content {
		height: 100%;
		.my-filter-table {
			width: 1755px;
			height: calc(100% -  20px);
			.my-filter-header-content {
				width: 100%;
				margin-bottom: 10px;
				.my-filter-head {
					display: flex;
					span {
						font-size: 13px;
						color: $black00-color;
					}
					.my-filter-head-th {
						min-width: 40px;
					}
					.my-filter-head-name {
						min-width: 150px;
					}
					.my-filter-head-salesperson {
						min-width: 110px;
					}
					.my-filter-head-state {
						min-width: 80px;
					}
					.my-filter-head-status {
						min-width: 90px;
					}
					.my-filter-head-category {
						min-width: 130px;
					}
					.my-filter-head-branch {
						min-width: 100px;
					}
					.my-filter-head-pendency {
						min-width: 80px;
					}
					.my-filter-head-pendency-code {
						min-width: 260px;
					}
					.my-filter-head-pendency-type {
						min-width: 200px;
					}
					.my-filter-head-pendency-resolver {
						min-width: 200px;
					}
					.my-filter-head-pendency-resolver-team {
						min-width: 200px;
					}
					.my-filter-head-pendency-date {
						min-width: 135px;
					}
					.my-filter-head-options {
						min-width: 10px;
					}
				}
			}
			.my-filter-body-content {
				width: 100%;
				display: block;
				max-height: 100%;
				overflow: overlay;
				.my-filter-body-list {
					display: flex;
					max-height: 100%;
					height: 60px;
					align-items: center;
					span {
						font-weight: 100;
						font-size: 12px;
						color: $black00-color;
						margin: 0;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					.my-filter-body-td {
						min-width: 20px;
					}
					&:nth-child(odd) {
						background-color: $greyE5-color;
					}
					.my-filter-body-name {
						display: flex;
						min-width: 150px;
						padding-right: 15px;
					}
					.my-filter-body-salesperson {
						display: flex;
						min-width: 110px;
						padding-right: 15px;
					}
					.my-filter-body-state {
						display: flex;
						min-width: 80px;
						padding-right: 15px;
					}
					.my-filter-body-status {
						display: flex;
						min-width: 90px;
						padding-right: 15px;
					}
					.my-filter-body-category {
						display: flex;
						min-width: 130px;
						padding-right: 15px;
					}
					.my-filter-body-branch {
						display: flex;
						min-width: 100px;
						padding-right: 15px;
					}
					.my-filter-body-pendency {
						display: flex;
						min-width: 80px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-code {
						display: flex;
						min-width: 260px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-type {
						display: flex;
						min-width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-resolver {
						display: flex;
						min-width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-resolver-team {
						display: flex;
						min-width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-resolver-team {
						display: flex;
						min-width: 135px;
						padding-right: 15px;
					}
					.my-filter-body-options {
						min-width: 10px;
						display: flex;
						align-items: center;
						margin-right: 10px;
					}
					&:hover {
						.my-filter-body-options {
							display: flex;
						}
					}
				}
			}
		}
	}

	.new-filter {
		position: relative;
		left: 20px;
		bottom: -15px;
		a {
			cursor: pointer;
			span {
				color: $blue00-color;
				margin-left: 5px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.my-filter-container {
		margin: 0px;
		height: calc(100% - 130px);
	
		.my-filter-content {
			height: 100%;
			width: 120%;
			overflow-x: overlay;

			.my-filter-table {
				height: 100%;
				
				.my-filter-body-content {
					height: calc(100% - 31px);

						.my-filter-body-options .ppsa-more-options-container .ppsa-more-options-menu {
							left: 20px;
						}
					}
				}
			}
		}
	}
