.promotional-codes {
    margin: -20px;
    max-height: 145px;
    height: 100%;
    overflow-y: overlay;
    border-radius: 0 0 10px 10px;
}

@media screen and (max-width: 480px) {
    .promotional-codes {
        max-height: 385px;
    }
}