.clients-list-container {
	width: 570px;
	margin: 0 -20px;
	height: 300px;
}

.device-mobile .clients-list-container {
	width: 100%;
	margin: 0px;
	height: 100%;
}

.client-input-container {
	margin: 0 20px 20px;
	div {
		width: 300px;
	}
}

.device-mobile .client-input-container {
	margin: 0;
	padding: 20px;
	div {
		width: 100%;
	}

	.ppsa-search-component {
		width: 100% !important;
	}
}

.clients-header-list {
	display: flex;

	.clients-details-list-item {
		padding: 0;
		margin: 0 10px 0 0;
		&:last-child {
			margin: 0;
		}
		span {
			font-weight: bold;
			opacity: 1;
			font-size: 13px;
			text-align: left;
			color: #333333;
			font-display: swap;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.clients-details-list {
		display: flex;
		padding: 0;
		margin: 0 20px 0;
		.clients-details-list-code {
			width: 50px;
		}
		.clients-details-list-company-name {
			width: 300px;
		}
		.clients-details-list-cnpj-cpf {
			width: 160px;
		}
	}
}

.device-mobile .clients-header-list {
	display: none;
}

.clients-body-list {
	height: 180px;
	max-height: 180px;
	overflow: overlay;
	.clients-body-item {
		cursor: pointer;
		display: flex;
		align-items: center;
		height: 30px;
		background-color: #f9f8fa;
		.clients-details-list-item {
			display: flex;
			padding: 0;
			margin: 0 10px 0 0;
			&:last-child {
				margin: 0;
			}
			span {
				font-weight: 400;
				opacity: 1;
				font-size: 13px;
				text-align: left;
				color: #333333;
				font-display: swap;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.clients-details-list {
			margin: 0 20px 0;
			display: flex;
			padding: 0;
			.clients-details-list-code {
				width: 50px;
			}
			.clients-details-list-company-name {
				width: 300px;
			}
			.clients-details-list-cnpj-cpf {
				width: 160px;
			}
		}
		&:hover {
			background-color: #d0dff0;
		}
	}
}
.device-mobile .clients-body-list {
	margin: 0px -10px;
	max-height: initial;
	height: calc(100dvh - 200px);

	.clients-body-item {
		height: auto;
		padding: 20px;

		.clients-details-list {
			width: 100%;
			margin: 0px;
			display: flex;
			overflow: hidden;
			flex-direction: column;

			.clients-details-list-item {
				width: auto;
				font-size: 12px;
				font-weight: 400;
			}
			.clients-details-list-item :first-child {
				font-weight: 700;
				margin-right: 5px;
			}

			.clients-details-list-company-name {
				width: 100%;
				display: flex;
				overflow: hidden;
				flex-direction: column;
				text-overflow: ellipsis;
			}
		}
	}
}

.clients-body-item:nth-child(odd) {
	background-color: #e5e5e5;
}
