.new-order-details-tab {
	gap: 20px;
	display: flex;
	margin: -20px 0px;
	padding: 2px 20px;
	overflow-y: overlay;
	flex-direction: column;
	height: calc(100vh - 120px);

	.section-container-action {
		gap: 20px;
		width: 100%;
		margin: auto;
		display: flex;
		max-width: 216px;
		align-items: center;
		flex-direction: row;
		justify-content: center;
	}

	.section-container-action-edit {
		gap: 20px;
		width: 100%;
		margin: 0 20px 0 auto;
		display: flex;
		max-width: 152px;
		flex-direction: row;
	}
}

.device-mobile .new-order-details-tab {
	padding: 20px;
	height: calc(100dvh - 70px);

	.section-container-action-edit {
		top: 20px;
		margin: 0px;
		right: 70px;
		max-width: 40px;
		position: absolute;
	}

	.section-component {
		margin: 20px 0px 0px;

		.section-component-body,
		.section-component-title {
			border-radius: 0px;
		}
	}
}