.new-client-details-tab {
	gap: 20px;
	display: flex;
	margin: 0px -20px;
	overflow-y: overlay;
	flex-direction: column;
	padding: 2px 20px 20px;
	height: calc(100vh - 120px);

	.section-container-action {
		gap: 20px;
		width: 100%;
		margin: auto;
		display: flex;
		max-width: 216px;
		align-items: center;
		flex-direction: row;
		justify-content: center;
	}

	.section-container-action-edit {
		gap: 20px;
		width: 100%;
		margin: 0 20px 0 auto;
		display: flex;
		max-width: 152px;
		flex-direction: row;
	}

	.new-order-details-tab-actions {
		gap: 20px;
		width: 100%;
		display: flex;
		margin-top: 20px;
		justify-content: center;

		button {
			width: 128px;
		}
	}
}

.device-mobile .new-client-details-tab {
	height: calc(100vh - 90px);

	.section-component {
		margin: 0px;

		.section-component-title {
			border-radius: 0px;
		}
	}

	.new-order-details-tab-actions {
		margin-top: 0px;
	}
}