.notifications-config-main-contain {
	width: 800px;
	padding: 0px;
	margin: 0px;

	@media screen and (max-width: 860px) {
		width: 100%;
	}
}

.device-mobile .notifications-config-main-contain {
	width: 100%;
	height: calc(var(--app-height) - 170px);
	.notifications-items-list {
		height: calc((var(--app-height) - 520px) / 2);
		overflow-y: scroll;
		margin-bottom: 20px;
		max-height: none;
	}
}

.modal-content.loading-content .notifications-config-main-contain {
	padding: 0px;
}

.notifications-config-main-contain-select-zone,
.notifications-config-main-contain-select {
	width: 650px;
	padding: 0px;
	margin: 0px;

	@media screen and (max-width: 860px) {
		width: 100%;
	}
}

.notifications-config-main-contain-select {
	height: 450px;
}

.device-mobile .notifications-config-main-contain-select {
	width: 100%;
	height: calc(var(--app-height) - 240px);

	.notifications-types-chefs-list,
	.notifications-types-list {
		height: calc(calc(var(--app-height) - 310px) / 2);
		overflow-y: scroll;
		margin-bottom: 20px;
	}

	.notifications-zones-list {
		height: calc(calc(var(--app-height) - 290px));
		overflow-y: scroll;
		margin-bottom: 20px;
	}
}

.device-mobile .notifications-config-main-contain-select-zone {
	width: 100%;
	height: calc(var(--app-height) - 203px);

	.notifications-zones-list {
		height: calc(calc(var(--app-height) - 240px));
		overflow-y: scroll;
		margin-bottom: 20px;
	}
}

.modal-content.loading-content .notifications-config-main-contain-select {
	padding: 0px;
}

.notifications-types-chefs-list,
.notifications-items-list,
.notifications-types-list,
.notifications-zones-list {
	margin-top: 10px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
}

.notifications-zones-list {
	height: 290px;
	overflow-y: scroll;
}

.notifications-types-chefs-list {
	height: 220px;
	overflow-y: scroll;
	padding-left: 10px;

	.checkbox-item {
		width: calc(90% - 5px) !important;
	}
}

.notifications-types-list {
	padding-left: 10px;
}

.checkbox-item {
	width: calc(33% - 5px) !important;
	span {
		margin-right: 4px;
	}
}

.checkbox-item .MuiTypography-body1 {
	font-weight: 400;
	font-size: 14px;
}

.notifications-items-list {
	padding: 0px 10px 0 0 !important;
	min-height: 50px;
	max-height: 130px;
	overflow: auto;
}

.notifications-items-list div {
	width: calc(50% - 5px) !important;
	margin-bottom: 4px;
}

.notifications-items-area-footer {
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.notifications-config-footer-contain {
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.wildcard-item-option {
	width: 100%;
	margin: 15px 0;
}

.wildcard-item-option .checkbox-item {
	width: 100%;
}

.notifications-items-area-footer {
	height: auto !important;
	flex-direction: row;
	margin: 15px 0 35px;
}

.notifications-items-area-footer p {
	display: block;
	color: #004687;
	font-size: 14px !important;
	font-weight: 500;
	text-align: left;
	line-height: 14px;
	text-decoration: underline;
	cursor: pointer;
}

.btn-confirm {
	height: 38px;
	border-radius: 3px;
	background-color: #004687;
	color: white;
	border: 0;
	margin: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	padding: 9px 15px;
}

.notification-item-configs-content {
	width: 500px;
	height: 350px;
	overflow: none;
	height: auto;
	border-radius: 3px;
	background-color: #fff;
	text-align: justify;

	box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.65);
}

@media screen and (max-width: 835px) {
	.notifications-items-list div {
		width: calc(100% - 5px) !important;
	}
	.checkbox-item {
		width: calc(50% - 5px) !important;
	}
}

@media screen and (max-width: 420px) {
	.notifications-items-list div,
	.checkbox-item {
		width: calc(100% - 5px) !important;
	}
}
