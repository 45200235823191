@import '../../../../../../../assets/styles/theme/colors/variables.scss';

.my-filter-container-clients {
	width: 800px;
	margin: 0 -20px;
	height: 245px;

	.my-filter-content {
		height: 200px;
		.my-filter-table {
			width: 100%;
			.my-filter-header-content {
				width: 100%;
				margin-bottom: 10px;
				.my-filter-head {
					display: flex;
					span {
						font-size: 13px;
						color: $black00-color;
					}
					.my-filter-head-th {
						width: 20px;
					}
					.my-filter-head-name {
						width: 200px;
					}
					.my-filter-head-salesperson {
						width: 280px;
					}
					.my-filter-head-state {
						width: 120px;
					}
					.my-filter-head-financial {
						width: 150px;
					}
					.my-filter-head-options {
						width: 10px;
					}
				}
			}
			.my-filter-body-content {
				width: 100%;
				display: block;
				max-height: 180px;
				overflow: overlay;
				.my-filter-body-list {
					display: flex;
					max-height: 180px;
					height: 60px;
					align-items: center;
					span {
						font-weight: 100;
						font-size: 12px;
						color: $black00-color;
						margin: 0;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					.my-filter-body-td {
						width: 20px;
					}
					&:nth-child(odd) {
						background-color: $greyE5-color;
					}
					.my-filter-body-name {
						display: flex;
						width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-salesperson {
						display: flex;
						width: 280px;
						padding-right: 15px;
					}
					.my-filter-body-state {
						display: flex;
						width: 120px;
						padding-right: 15px;
					}
					.my-filter-body-financial {
						display: flex;
						width: 150px;
						padding-right: 15px;
					}
					.my-filter-body-options {
						width: 10px;
						display: none;
						align-items: center;
					}
					&:hover {
						.my-filter-body-options {
							display: flex;
						}
					}
				}
			}
		}
	}

	.new-filter {
		position: relative;
		left: 20px;
		bottom: -15px;
		a {
			cursor: pointer;
			span {
				color: $blue00-color;
				margin-left: 5px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.my-filter-container-clients {
		margin: 0px;
		height: calc(100% - 130px);
		width: 100%;
	
		.my-filter-content {
			height: 100%;
			overflow-x: overlay;

			.my-filter-table {
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				overflow-x: auto;
				
				.my-filter-body-content {
					height: calc(100% - 31px);
				}
			}
		}
	}
}
