@import '../../../../../../../assets/styles/theme/colors/variables.scss';

.my-filter-container-orders {
	width: 800px;
	margin: 0 -20px;
	height: 250px;

	.my-filter-content {
		height: 214px;
		overflow-x: overlay;
		overflow-y: hidden;
		.my-filter-table {
			width: 1735px;
			.my-filter-header-content {
				width: 100%;
				margin-bottom: 5px;
				.my-filter-head {
					display: flex;
					span {
						font-size: 13px;
						color: $black00-color;
					}
					.my-filter-head-th {
						width: 20px;
					}
					.my-filter-head-name {
						width: 150px;
					}
					.my-filter-head-salesperson {
						width: 110px;
					}
					.my-filter-head-state {
						width: 80px;
					}
					.my-filter-head-status {
						width: 90px;
					}
					.my-filter-head-category {
						width: 130px;
					}
					.my-filter-head-branch {
						width: 100px;
					}
					.my-filter-head-pendency {
						width: 80px;
					}
					.my-filter-head-pendency-code {
						width: 260px;
					}
					.my-filter-head-pendency-type {
						width: 200px;
					}
					.my-filter-head-pendency-resolver {
						width: 200px;
					}
					.my-filter-head-pendency-resolver-team {
						width: 200px;
					}
					.my-filter-head-pendency-date {
						width: 135px;
					}
					.my-filter-head-options {
						width: 10px;
						margin-right: 10px;
					}
				}
			}
			.my-filter-body-content {
				width: 100%;
				display: block;
				max-height: 180px;
				overflow: overlay;
				.my-filter-body-list {
					display: flex;
					max-height: 180px;
					height: 60px;
					align-items: center;
					span {
						font-weight: 100;
						font-size: 12px;
						color: $black00-color;
						margin: 0;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
					.my-filter-body-td {
						width: 10px;
					}
					&:nth-child(odd) {
						background-color: $greyE5-color;
					}
					.my-filter-body-name {
						display: flex;
						width: 150px;
						padding-right: 15px;
					}
					.my-filter-body-salesperson {
						display: flex;
						width: 110px;
						padding-right: 15px;
					}
					.my-filter-body-state {
						display: flex;
						width: 80px;
						padding-right: 15px;
					}
					.my-filter-body-status {
						display: flex;
						width: 90px;
						padding-right: 15px;
					}
					.my-filter-body-category {
						display: flex;
						width: 130px;
						padding-right: 15px;
					}
					.my-filter-body-branch {
						display: flex;
						width: 100px;
						padding-right: 15px;
					}
					.my-filter-body-pendency {
						display: flex;
						width: 80px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-code {
						display: flex;
						width: 260px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-type {
						display: flex;
						width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-resolver {
						display: flex;
						width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-resolver-team {
						display: flex;
						width: 200px;
						padding-right: 15px;
					}
					.my-filter-body-pendency-resolver-team {
						display: flex;
						width: 135px;
						padding-right: 15px;
					}
					.my-filter-body-options {
						width: 20px;
						height: 35px;
						align-items: center;
						margin-right: 10px;
						justify-content: center;

						.options {
							display: none;
						}

						.ppsa-more-options-container {
							position: absolute;
						}
					}
					&:hover {
						.my-filter-body-options {
							.options {
								display: flex;
							}
						}
					}
				}
			}
		}
	}

	.new-filter {
		position: relative;
		left: 20px;
		bottom: -10px;
		a {
			cursor: pointer;
			span {
				color: $blue00-color;
				margin-left: 5px;
			}
		}
	}
}
