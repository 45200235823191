.orders-filter {
	.orders-filter-content {
		width: 440px;
		position: relative;

		span {
			font-size: 12px;
			font-weight: bold;
		}

		.my-filter {
			top: 0;
			width: 100%;
			display: flex;
			cursor: pointer;
			position: absolute;
			justify-content: center;

			span {
				font-size: 12px;
				color: #025cb7;
			}
		}

		.clear-filter {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;

			span {
				color: #025cb7;
			}
		}

		.MuiCheckbox-root {
			margin-bottom: 0px !important;
		}
		.MuiIconButton-label {
			margin-bottom: 0px !important;
		}

		.period-filter-keyboard-button {
			padding-right: 10px !important;
			.MuiIconButton-label {
				margin-bottom: 0px !important;
			}
			svg {
				color: #025cb7;
				width: 1em !important;
				height: 1em !important;
			}
			&:focus {
				outline: none;
			}
			&:hover {
				background-color: transparent;
			}
		}

		@media screen and (max-width: 479px) {
			.clear-filter {
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
				span {
					color: #025cb7;
					font-size: 12px;
					font-weight: bold;
				}
			}
		}
	}
}

.orders-filter-actions {
	display: flex;
	position: static;
	column-gap: 20px;
	justify-content: center;
	
	div {
		width: 0px;
	}
	
	button {
		width: 120px;
	}
}

@media screen and (max-width: 480px) {
	.orders-filter {
		width: 100%;
		padding: 20px;
		height: calc(100dvh - 200px);
		overflow-y: auto;

		.orders-filter-content {
			width: 100%;
			position: static;
			height: 100%;
	
			.orders-filter-header-actions {
				width: 100%;
				display: flex;
				justify-content: center;
				gap: 55%;

				span {
					color: #025cb7;
				}
			}
	
			.MuiCheckbox-root {
				margin-bottom: 0px !important;
			}
			.MuiIconButton-label {
				margin-bottom: 0px !important;
			}
	
			.clear-filter {
				position: static;
				cursor: pointer;

				span {
					color: #025cb7;
					font-size: 12px;
					font-weight: bold;
				}
			}
		}
	
	}
	.orders-filter-actions {
		display: flex;
		width: 100%;
		padding: 20px;
		position: static;
		column-gap: 20px;
		justify-content: center;
		div {
			width: 0px;
		}
		button {
			width: 120px;
		}
	}
}