.clients-filter-content {
	width: 440px;
	position: relative;

	span {
		font-size: 13px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.my-filter {
		top: 0;
		width: 100%;
		display: flex;
		cursor: pointer;
		position: absolute;
		justify-content: center;

		span {
			font-size: 12px;
			color: #025cb7;
		}
	}

	.clear-filter {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;

		span {
			color: #025cb7;
		}
	}

	.MuiCheckbox-root {
		margin-bottom: 0px !important;
	}
	.MuiIconButton-label {
		margin-bottom: 0px !important;
	}

	.period-filter-keyboard-button {
		padding-right: 10px !important;
		.MuiIconButton-label {
			margin-bottom: 0px !important;
		}
		svg {
			color: #025cb7;
			width: 1em !important;
			height: 1em !important;
		}
	}

	.clients-filter-actions {
		display: flex;
		position: static;
		margin-top: 25px;
		flex-direction: row;
		justify-content: center;
		gap: 20px;

		button {
			width: 108px;
		}
	}

	@media screen and (max-width: 479px) {
		.clear-filter {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;

			span {
				color: #025cb7;
				font-size: 12px;
				font-weight: bold;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.clients-filter-content {
		width: 100%;
		padding: 20px;
		height: calc(100dvh - 140px);
		position: relative;

		.clients-filter-header-actions {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 50%;

			span {
				color: #025cb7;
			}
		}

		.block-filter .block-filter-fields label {
			width: auto;
		}

		.clients-filter-actions {
			width: 100%;
			display: flex;
			justify-content: center;
			position: absolute;
			bottom: 0;
		}
	}
}
